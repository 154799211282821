import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Lookup } from '../helpers/lookup';

import { CommunityDataLevel, ICommunity } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private lookup: Lookup) {}

  getCommunityById(id: number, dataLevel: CommunityDataLevel): Observable<ICommunity> {
    return this.http.get<ICommunity>(
      `/api/communities/${id}?dataLevel=${dataLevel}&filterToActiveOnly=true`
    );
  }

  makePassThruRequest<T>(url: string): Promise<T> {
    return this.http.get<T>(`/api/utility/pass-thru?url=${url}`).toPromise();
  }

  getPdfResourceAsImage(url: string) {
    const options = { responseType: 'arraybuffer' as const };
    return this.http.get(`/api/utility/get-pdf-resource-as-image?url=${url}`, options);
  }

  private formatTypeName(typeName: string): string {
    if (typeName.startsWith('II')) typeName = typeName.slice(1);
    if (typeName.endsWith('VM')) typeName = typeName.slice(0, -2);
    return `${typeName.toLowerCase()}s`;
  }

  getById<T>(id: number | string, typeName: string, additionalPath: string = ''): Promise<T> {
    typeName = this.formatTypeName(typeName);
    const path = `/api/${typeName}/${id}` + (additionalPath ? '/' + additionalPath : '');
    return this.http.get<T>(path).toPromise();
  }

  getByUrl<T>(url: string): Observable<T> {
    return this.http.get<T>(`/api/${url}`);
  }

  create<T>(newObject: T, typeName: string, optionalParamString?: string): Observable<T> {
    typeName = this.formatTypeName(typeName);
    return this.http.post<T>(`/api/${typeName}${optionalParamString || ''}`, newObject);
  }

  postForm(form: FormData, apiEndpoint?: string, optionalParamString?: string): Observable<string> {
    return this.http.post<string>(`/api/${apiEndpoint}${optionalParamString || ''}`, form);
  }

  postObj<T>(newObject: T, apiEndpoint?: string, optionalParamString?: string): Observable<string> {
    return this.http
      .post<string>(`/api/${apiEndpoint}${optionalParamString || ''}`, newObject)
      .pipe(catchError(this.handleError));
  }

  putObj<T>(newObject: T, apiEndpoint?: string, optionalParamString?: string): Observable<string> {
    return this.http
      .put<string>(`/api/${apiEndpoint}${optionalParamString || ''}`, newObject)
      .pipe(catchError(this.handleError));
  }

  update<T>(newObject: T, typeName: string, optionalParamString?: string): Promise<T> {
    typeName = this.formatTypeName(typeName);
    return this.http.put<T>(`/api/${typeName}${optionalParamString || ''}`, newObject).toPromise();
  }

  deleteById<T>(id: number | string, typeName: string): Observable<T> {
    typeName = this.formatTypeName(typeName);
    return this.http.delete<T>(`/api/${typeName}/${id}`);
  }

  getWithNoAuth(url: string) {
    const options = {
      headers: new HttpHeaders({ [this.lookup.SkipAuthHeader]: 'true' }),
      responseType: 'text' as 'text' // casting as 'text' because nonsense Angular type declaration issue
    };
    return this.http.get(url, options).toPromise();
  }

  downloadFromUrl(url: string): Promise<HttpResponse<ArrayBuffer>> {
    return firstValueFrom(this.http.get(url, { responseType: 'arraybuffer', observe: 'response' }));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
