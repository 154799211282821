import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { enterLeaveFadeAnimation } from '../../global/animations';
import { ToastService } from '../../global/toast/toast.service';
import { sortByName } from '../../utility/helpers/sorters';
import { FloorplanVM } from '../../utility/models/floorplan-vm';
import { SettingsService } from '../../utility/services/settings.service';
import { ThemeService } from '../../utility/services/theme.service';
import { FloorplanFilters, IHFilterType } from '../floorplan-filters/floorplan-filters';
import { SpecsPluralPipe } from '../specsplural.pipe';

@Component({
  selector: 'floorplan-filters',
  templateUrl: './floorplan-filters.component.html',
  styleUrls: ['./floorplan-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFadeAnimation()]
})
export class FloorplanFiltersComponent implements OnChanges, OnInit {
  @Input() floorplans: Array<FloorplanVM> = [];
  @Input() displayCounter: string;
  @Input() filters: FloorplanFilters;
  @Input() showInventoryHomes = false;
  @Input() isEnabled: boolean;
  @Output() isEnabledChange = new EventEmitter<boolean>();
  @Output() filtersChanged = new EventEmitter<FloorplanFilters>();
  isOpen = true;

  floorSettingsLabel: string;
  bedroomSettingsLabel: string;
  bathroomSettingsLabel: string;
  garageSizeSettingsLabel: string;
  sqftSettingsLabel: string;

  hidePriceFilter = false;
  hideFloorsFilter = false;
  hideBedroomsFilter = false;
  hideBathroomsFilter = false;
  hideGarageFilter = false;
  hideSqftFilter = false;

  inventoryHomeFilterValues: Map<IHFilterType, string> = new Map<IHFilterType, string>([
    [IHFilterType.Show, 'Show'],
    [IHFilterType.ShowOnly, 'Show Only'],
    [IHFilterType.Hide, 'Hide']
  ]);

  get selectedFloorplan(): FloorplanVM {
    return this.floorplans.find(
      floorplan => floorplan.FloorplanId === this.filters.SelectedFloorplanId
    );
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public theme: ThemeService,
    private toaster: ToastService,
    private specPluralPipe: SpecsPluralPipe,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.floorSettingsLabel = this.specPluralPipe.getPluralizedLabel('SpecFloorLabel') || 'Floors';
    this.bedroomSettingsLabel =
      this.specPluralPipe.getPluralizedLabel('SpecBedroomLabel') || 'Bedrooms';
    this.bathroomSettingsLabel =
      this.specPluralPipe.getPluralizedLabel('SpecBathroomLabel') || 'Bathrooms';
    this.garageSizeSettingsLabel =
      this.specPluralPipe.getPluralizedLabel('SpecGarageTypeLabel') || 'Garage Size';
    this.sqftSettingsLabel =
      this.specPluralPipe.getPluralizedLabel('SpecSqFtLabel') || 'Sq Footage';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.floorplans) {
      this.floorplans = this.floorplans.sort(sortByName);
      // this seems to run more than I expected on the FP list page... needed for ISM when FP selected and switch hoods
      if (this.filters.IsFloorplanFilterSet && !this.selectedFloorplan) {
        this.filters.clearSelectedFloorplan();
        this.handleFilterChange();
      }
      this.handleFiltersHidden();
    }
  }

  toggleOpen(open: boolean) {
    this.isOpen = open;
    this.cdr.detectChanges();
  }

  handleFiltersEnabledToggle(isFiltersEnabled: boolean) {
    this.isEnabledChange.emit(isFiltersEnabled);
    this.filtersChanged.emit(this.filters);
    this.isEnabled = isFiltersEnabled;
  }

  handleFilterChange() {
    if (this.filters.IsAnyFilterSet) {
      this.isEnabledChange.emit(true);
    } else {
      this.isEnabledChange.emit(false);
    }
    this.filtersChanged.emit(this.filters);
  }

  handleFilterByInventoryHome() {
    if (this.filters.IsInventoryHomeFilterSet) {
      this.isEnabledChange.emit(true);
    }
    this.handleFilterChange();
  }

  handleReset() {
    this.toaster
      .showConfirm(
        'Are you sure?',
        'You are about to remove all filters. This action cannot be undone.'
      )
      .then((shouldContinue: boolean) => {
        if (!shouldContinue) return;
        this.filters.resetFilterState();
        this.handleFilterChange();
      });
  }

  handleFiltersHidden() {
    if (this.settingsService.get('HideUnusedFloorplanFilters')) {
      this.hidePriceFilter = !this.filters.priceOptions.length;
      this.hideFloorsFilter = this.filters.MaxFloors <= 0;
      this.hideBedroomsFilter = this.filters.MaxBedrooms <= 0;
      this.hideBathroomsFilter = this.filters.MaxBathrooms <= 0;
      this.hideGarageFilter = this.filters.MaxGarageSize <= 0;
      this.hideSqftFilter = !this.filters.sqFootOptions.length;
    }
  }

  trackByFloorPlanId(index: number, floorplan: FloorplanVM) {
    return floorplan.FloorplanId;
  }
}
