import { Injectable } from '@angular/core';

import { IBaseContentJson, PageVersion } from '../interfaces/page';
import { PageVM } from '../models/page-vm';
import { CommunityService } from './community.service';
import { PageTitle } from './navigation.service';

import { IPage } from '@ml/common';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  constructor(private communityService: CommunityService, private api: ApiService) {}

  getById<T extends IBaseContentJson>(id: number): PageVM<T> {
    if (!this.communityService.current.Pages) return null;

    const page = this.communityService.current.Pages.find(x => x.PageId === id);
    if (!page) return null;

    return new PageVM<T>(page);
  }

  getAll<T extends IBaseContentJson>(version?: PageVersion): PageVM<T>[] {
    if (!this.communityService.current.Pages) return null;

    let pages = this.communityService.current.Pages;
    if (version) pages = pages.filter(x => x.Version === version);

    return pages.map(p => new PageVM<T>(p));
  }

  get<T extends IBaseContentJson>(pageTitle: PageTitle): PageVM<T> {
    if (!this.communityService.current.Pages) return null;

    return new PageVM<T>(
      this.communityService.current.Pages.find(
        x => x.PageTitle.toLowerCase() === pageTitle.toLowerCase()
      )
    );
  }

  getBaseFileUrl(page: IPage): string {
    const communityBaseUrl = this.communityService.getBaseApiUrl();
    const pagePath = `Pages/${page.PageTitle}_${page.PageId}`;
    return `${communityBaseUrl}/${pagePath}`;
  }

  getPageDownloadableContent(pageId: number) {
    const page = this.communityService.current.Pages.find(p => p.PageId === pageId);
    if (page) {
      const url = `/api/pages/download/${pageId}`;
      const fileName = `${page.NavigationTitle || page.PageTitle}.zip`;
      this.api
        .downloadFromUrl(url)
        .then(res => {
          const contentType = res.headers.get('Content-Type') || 'application/zip';
          const blob = new Blob([res.body], { type: contentType });
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          return blob;
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
}
