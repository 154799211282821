import { roundDownToPrecision, roundUpToPrecision } from '../../utility/helpers/helpers';
import { IFloorPlan } from '../../utility/interfaces/floorplan';
import { FloorplanVM } from '../../utility/models/floorplan-vm';
import { InventoryHomeVM } from '../../utility/models/inventory-home-vm';

export class FloorplanFilters implements IFloorplanFiltersState {
  public readonly filterUnsetValue = -1;
  readonly PricePrecision = 10000;
  readonly SqFootagePrecision = 500;
  readonly RangeFilterTotalOptions = 10;

  private defaultInventoryHomeFilterStatus = IHFilterType.Show;

  // filter state properties
  MinFloors = 0;
  MinBedrooms = 0;
  MinBathrooms = 0;
  MinGarageSize = 0;
  MinSqFootage = this.filterUnsetValue;
  MaxSqFootage = this.filterUnsetValue;
  MinPrice = this.filterUnsetValue;
  MaxPrice = this.filterUnsetValue;
  SelectedFloorplanId = this.filterUnsetValue;
  InventoryHomeFilterStatus = this.defaultInventoryHomeFilterStatus;

  MaxFloors = 0;
  MaxBathrooms = 0;
  MaxGarageSize = 0;
  MaxBedrooms = 0;

  priceOptions: Array<number> = [];
  sqFootOptions: Array<number> = [];

  constructor(data?: IFloorplanFiltersState) {
    Object.assign(this, data);
  }

  get FormattedSqFootageRange(): string {
    if (!this.IsSqFootFilterSet) return '';
    if (this.MinSqFootage === this.filterUnsetValue)
      return `up to ${this.MaxSqFootage.toLocaleString()} sq. ft.`;
    if (this.MaxSqFootage === this.filterUnsetValue)
      return `${this.MinSqFootage.toLocaleString()} sq. ft. and up`;
    return `${this.MinSqFootage.toLocaleString()} - ${this.MaxSqFootage.toLocaleString()} sq. ft.`;
  }

  get FormattedPriceRange(): string {
    if (!this.IsPriceFilterSet) return '';
    if (this.MinPrice === this.filterUnsetValue) return `up to $${this.MaxPrice.toLocaleString()}`;
    if (this.MaxPrice === this.filterUnsetValue) return `$${this.MinPrice.toLocaleString()} and up`;
    return `$${this.MinPrice.toLocaleString()} - $${this.MaxPrice.toLocaleString()}`;
  }

  get IsAnyFilterSet(): boolean {
    return !!(
      this.MinFloors ||
      this.MinBedrooms ||
      this.MinBathrooms ||
      this.MinGarageSize ||
      this.IsSqFootFilterSet ||
      this.IsPriceFilterSet ||
      this.IsFloorplanFilterSet ||
      this.IsInventoryHomeFilterSet
    );
  }

  get IsFloorplanFilterSet(): boolean {
    return this.SelectedFloorplanId !== this.filterUnsetValue;
  }

  get IsPriceFilterSet(): boolean {
    return this.MinPrice !== this.filterUnsetValue || this.MaxPrice !== this.filterUnsetValue;
  }

  get IsSqFootFilterSet(): boolean {
    return (
      this.MinSqFootage !== this.filterUnsetValue || this.MaxSqFootage !== this.filterUnsetValue
    );
  }

  get IsInventoryHomeFilterSet(): boolean {
    return this.InventoryHomeFilterStatus !== this.defaultInventoryHomeFilterStatus;
  }

  generateOptions(minValue: number, maxValue: number, precision: number) {
    const lowestOption = roundDownToPrecision(minValue, precision);

    const step = this.calculateStep(lowestOption, maxValue, precision);

    if (step === 0) return maxValue > 0 ? [maxValue] : [];

    const highestOption = (maxValue - lowestOption) % step !== 0 ? maxValue + step : maxValue;

    const options: number[] = [];
    for (let i = lowestOption; i <= highestOption; i += step) {
      options.push(i);
    }

    return options;
  }

  private calculateStep(minValue: number, maxValue: number, precision: number) {
    const totalSteps = this.RangeFilterTotalOptions - 1; // 9 steps in btwn 10 options
    const minMaxDiff = maxValue - minValue;
    const stepValue = minMaxDiff / totalSteps;
    return roundUpToPrecision(stepValue, precision);
  }

  public setFilterRanges(
    floorplans: FloorplanVM[],
    inventoryHomes?: InventoryHomeVM[],
    lotPrices?: number[]
  ) {
    let minPrice = 0;
    let maxPrice = 0;
    let minSqFootage = 0;
    let maxSqFootage = 0;
    let maxFloors = 0;
    let maxBathrooms = 0;
    let maxGarageSize = 0;
    let maxBedrooms = 0;

    if (floorplans.length) {
      minPrice = this.getMinPropValue(floorplans, fp => this.PriceExceptZero(fp));
      maxPrice = this.getMaxPropValue(floorplans, fp => this.PriceExceptZero(fp));
      minSqFootage = this.getMinPropValue(floorplans, fp => this.SqFtExceptZero(fp));
      maxSqFootage = this.getMaxPropValue(floorplans, fp => this.SqFtExceptZero(fp));

      maxFloors = this.getMaxPropValue(
        floorplans,
        fp => fp.FloorRangeMax || fp.DefaultNumberOfFloors
      );
      maxBathrooms = this.getMaxPropValue(
        floorplans,
        fp => fp.NumberOfFullBathsRangeMax || fp.CombinedBathrooms
      );
      maxGarageSize = this.getMaxPropValue(
        floorplans,
        fp => fp.GarageCarSizeRangeMax || fp.GarageCarSize
      );
      maxBedrooms = this.getMaxPropValue(
        floorplans,
        fp => fp.NumberOfBedroomsRangeMax || fp.NumberOfBedrooms
      );
    }

    if (inventoryHomes?.length) {
      minPrice = this.getMinPropValue(inventoryHomes, ih => this.PriceExceptZero(ih), minPrice);
      maxPrice = this.getMaxPropValue(inventoryHomes, ih => this.PriceExceptZero(ih), maxPrice);
      minSqFootage = this.getMinPropValue(
        inventoryHomes,
        ih => this.SqFtExceptZero(ih),
        minSqFootage
      );
      maxSqFootage = this.getMaxPropValue(
        inventoryHomes,
        ih => this.SqFtExceptZero(ih),
        maxSqFootage
      );

      maxFloors = this.getMaxPropValue(
        inventoryHomes,
        ih => ih.FloorPlan.DefaultNumberOfFloors,
        maxFloors
      );
      maxBathrooms = this.getMaxPropValue(inventoryHomes, ih => ih.CombinedBathrooms, maxBathrooms);
      maxGarageSize = this.getMaxPropValue(inventoryHomes, ih => ih.GarageCarSize, maxGarageSize);
      maxBedrooms = this.getMaxPropValue(inventoryHomes, ih => ih.NumberOfBedrooms, maxBedrooms);
    }

    if (lotPrices?.length) {
      minPrice = this.getMinPropValue(lotPrices, lp => (lp === 0 ? undefined : lp), minPrice);
      maxPrice = this.getMaxPropValue(lotPrices, lp => (lp === 0 ? undefined : lp), maxPrice);
    }

    this.priceOptions = this.generateOptions(minPrice, maxPrice, this.PricePrecision);
    this.sqFootOptions = this.generateOptions(minSqFootage, maxSqFootage, this.SqFootagePrecision);

    this.MaxFloors = maxFloors;
    this.MaxBathrooms = maxBathrooms;
    this.MaxGarageSize = maxGarageSize;
    this.MaxBedrooms = maxBedrooms;

    if (this.IsAnyFilterSet) {
      this.verifyFilterStatesWithinRanges(floorplans);
    }
  }

  private verifyFilterStatesWithinRanges(floorplans: FloorplanVM[]) {
    if (this.MinFloors && this.MinFloors > this.MaxFloors) {
      this.MinFloors = this.MaxFloors;
    }

    if (this.MinBedrooms && this.MinBedrooms > this.MaxBedrooms) {
      this.MinBedrooms = this.MaxBedrooms;
    }

    if (this.MinBathrooms && this.MinBathrooms > this.MaxBathrooms) {
      this.MinBathrooms = this.MaxBathrooms;
    }

    if (this.MinGarageSize && this.MinGarageSize > this.MaxGarageSize) {
      this.MinGarageSize = this.MaxGarageSize;
    }

    if (this.IsSqFootFilterSet) {
      if (
        this.MinSqFootage !== this.filterUnsetValue &&
        !this.sqFootOptions.includes(this.MinSqFootage)
      ) {
        this.MinSqFootage = this.filterUnsetValue;
      }
      if (
        this.MaxSqFootage !== this.filterUnsetValue &&
        !this.sqFootOptions.includes(this.MaxSqFootage)
      ) {
        this.MaxSqFootage = this.filterUnsetValue;
      }
    }

    if (this.IsPriceFilterSet) {
      if (this.MinPrice !== this.filterUnsetValue && !this.priceOptions.includes(this.MinPrice)) {
        this.MinPrice = this.filterUnsetValue;
      }
      if (this.MaxPrice !== this.filterUnsetValue && !this.priceOptions.includes(this.MaxPrice)) {
        this.MaxPrice = this.filterUnsetValue;
      }
    }

    if (
      this.IsFloorplanFilterSet &&
      !floorplans.find(fp => fp.FloorplanId === this.SelectedFloorplanId)
    ) {
      this.clearSelectedFloorplan();
    }

    // don't need to verify InventoryHomeFilter
  }

  private PriceExceptZero(fp: FloorplanVM | InventoryHomeVM): number {
    return fp.Price === 0 ? undefined : fp.Price;
  }

  // TODO - fix saving FPs in Converge to save price and sqft as blank/null instead of defaulting to 0
  private SqFtExceptZero(fp: FloorplanVM | InventoryHomeVM): number {
    return fp instanceof FloorplanVM
      ? fp.BaseTotalSquareFeet === 0
        ? undefined
        : fp.BaseTotalSquareFeet
      : fp.TotalSquareFeet === 0
      ? undefined
      : fp.TotalSquareFeet;
  }

  private getMinPropValue<T extends FloorplanVM | InventoryHomeVM | number>(
    array: T[],
    propertyToGet: (item: T) => number,
    currentMin?: number
  ): number {
    const values = (array as []).map(propertyToGet);
    values.push(currentMin);
    return Math.min(...values.filter(x => this.isValidNumber(x)));
  }

  private getMaxPropValue<T extends FloorplanVM | InventoryHomeVM | number>(
    array: T[],
    propertyToGet: (item: T) => number,
    currentMax?: number
  ): number {
    const values = (array as []).map(propertyToGet);
    values.push(currentMax);
    return Math.max(...values.filter(x => this.isValidNumber(x)));
  }

  private isValidNumber(x: number): boolean {
    return x !== undefined && x !== null;
  }

  private isSelectedFloorplanIncluded(floorplan: IFloorPlan) {
    return !this.IsFloorplanFilterSet || floorplan.FloorplanId === this.SelectedFloorplanId;
  }

  isPriceIncluded(price: number): boolean {
    if (!this.IsPriceFilterSet) return true;

    if (!price) return true;
    let isIncluded = true;
    if (this.MinPrice !== this.filterUnsetValue) isIncluded = isIncluded && price >= this.MinPrice;
    if (this.MaxPrice !== this.filterUnsetValue) isIncluded = isIncluded && price <= this.MaxPrice;

    return isIncluded;
  }

  private isSqFootageIncluded(sf: number): boolean {
    if (!this.IsSqFootFilterSet) return true;
    let isIncluded = typeof sf === 'number';
    if (this.MinSqFootage !== this.filterUnsetValue)
      isIncluded = isIncluded && sf >= this.MinSqFootage;
    if (this.MaxSqFootage !== this.filterUnsetValue)
      isIncluded = isIncluded && sf <= this.MaxSqFootage;

    return isIncluded;
  }

  private isInventoryHomeIncluded(): boolean {
    return (
      this.InventoryHomeFilterStatus === IHFilterType.Show ||
      this.InventoryHomeFilterStatus === IHFilterType.ShowOnly
    );
  }

  private isNotInventoryHome(): boolean {
    return this.InventoryHomeFilterStatus !== IHFilterType.ShowOnly;
  }

  private compareSpecs(
    currentValue: number,
    valueToBeat: number,
    currentValueOverride: number = null
  ): boolean {
    if (currentValueOverride !== null) return currentValueOverride >= valueToBeat;
    return currentValue >= valueToBeat;
  }

  isFloorplanIncluded(floorplan: FloorplanVM): boolean {
    return (
      this.isSelectedFloorplanIncluded(floorplan) &&
      this.isPriceIncluded(floorplan.Price) &&
      this.compareSpecs(floorplan.DefaultNumberOfFloors, this.MinFloors, floorplan.FloorRangeMax) &&
      this.compareSpecs(
        floorplan.NumberOfBedrooms,
        this.MinBedrooms,
        floorplan.NumberOfBedroomsRangeMax
      ) &&
      this.compareSpecs(
        floorplan.CombinedBathrooms,
        this.MinBathrooms,
        floorplan.NumberOfFullBathsRangeMax
      ) &&
      this.compareSpecs(
        floorplan.GarageCarSize,
        this.MinGarageSize,
        floorplan.GarageCarSizeRangeMax
      ) &&
      this.isSqFootageIncluded(floorplan.BaseTotalSquareFeet) &&
      this.isNotInventoryHome()
    );
  }

  isInventoryHomeFloorplanIncluded(ih: InventoryHomeVM): boolean {
    const ihSqFtIncluded =
      ih.TotalSquareFeet === null
        ? this.isSqFootageIncluded(ih.FloorPlan.BaseTotalSquareFeet)
        : this.isSqFootageIncluded(ih.TotalSquareFeet);
    return (
      this.isSelectedFloorplanIncluded(ih.FloorPlan) &&
      this.isPriceIncluded(ih.Price || ih.FloorPlan.Price) &&
      this.compareSpecs(ih.FloorPlan.DefaultNumberOfFloors, this.MinFloors) &&
      this.compareSpecs(ih.FloorPlan.NumberOfBedrooms, this.MinBedrooms, ih.NumberOfBedrooms) &&
      this.compareSpecs(ih.FloorPlan.CombinedBathrooms, this.MinBathrooms, ih.CombinedBathrooms) &&
      this.compareSpecs(ih.FloorPlan.GarageCarSize, this.MinGarageSize, ih.GarageCarSize) &&
      ihSqFtIncluded &&
      this.isInventoryHomeIncluded()
    );
  }

  public resetFilterState() {
    this.MinFloors = 0;
    this.MinBedrooms = 0;
    this.MinBathrooms = 0;
    this.MinGarageSize = 0;
    this.MinSqFootage = this.filterUnsetValue;
    this.MaxSqFootage = this.filterUnsetValue;
    this.MinPrice = this.filterUnsetValue;
    this.MaxPrice = this.filterUnsetValue;
    this.SelectedFloorplanId = this.filterUnsetValue;
    this.InventoryHomeFilterStatus = this.defaultInventoryHomeFilterStatus;
  }

  public getFloorplanFiltersState(): IFloorplanFiltersState {
    return {
      MinFloors: this.MinFloors,
      MinBedrooms: this.MinBedrooms,
      MinBathrooms: this.MinBathrooms,
      MinGarageSize: this.MinGarageSize,
      MinSqFootage: this.MinSqFootage,
      MaxSqFootage: this.MaxSqFootage,
      MinPrice: this.MinPrice,
      MaxPrice: this.MaxPrice,
      SelectedFloorplanId: this.SelectedFloorplanId,
      InventoryHomeFilterStatus: this.InventoryHomeFilterStatus
    } as IFloorplanFiltersState;
  }

  clearSelectedFloorplan() {
    this.SelectedFloorplanId = this.filterUnsetValue;
  }
}

export enum IHFilterType {
  Show,
  ShowOnly,
  Hide
}

export interface IFloorplanFiltersState {
  MinFloors: number;
  MinBedrooms: number;
  MinBathrooms: number;
  MinGarageSize: number;
  MinSqFootage: number;
  MaxSqFootage: number;
  MinPrice: number;
  MaxPrice: number;
  SelectedFloorplanId: number;
  InventoryHomeFilterStatus: IHFilterType;
}
